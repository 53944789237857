/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import '../styles/globals.css';
import { InvoiceProvider } from '../context/state';

function MyApp({ Component, pageProps }) {
  return (
    <InvoiceProvider>
      <Component {...pageProps} />
    </InvoiceProvider>
  );
}

export default MyApp;
