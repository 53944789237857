import React, {
  createContext, useReducer, useContext,
} from 'react';

const InvoiceStateContext = createContext();

const SET_INVOICE = 'SET_INVOICE';

const initialState = {
  submit: false,
  method: '',
  methodType: '',
  accountNumber: '',
  failureCode: '',
  date: 0,
  expired: false,
  cancelled: false,
  data: null,
  voucher: null,
  methodData: null,
  totalPayment: 0,
  voucherValue: 0,
  totalPaymentAfterVoucher: 0,
  finalStatus: 'pending',
  nextPage: false,
  methodFee: 0,
  feeAfterDiscount: 0,
  feeCreditCardBeforeDiscount: 0,
  feeCreditCard: 0,
  paymentExpired: null,
  urlCode: null,
  sellerPhone: null,
  referenceID: null,
  customerNumber: '',
  card_number: null,
  card_expiry: null,
  card_cvn: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INVOICE:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export function InvoiceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <InvoiceStateContext.Provider value={[state, dispatch]}>
      {children}
    </InvoiceStateContext.Provider>
  );
}

export const useInvoiceState = () => useContext(InvoiceStateContext);
